// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Settings page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
// import Sidenav from "layouts/pages/account/settings/components/Sidenav";
// import Header from "layouts/pages/account/settings/components/Header";
// import BasicInfo from "layouts/pages/account/settings/components/BasicInfo";
// import ChangePassword from "layouts/pages/account/settings/components/ChangePassword";
// import Authentication from "layouts/pages/account/settings/components/Authentication";
import Accounts from "layouts/configs/settings/components/Accounts";
// import Notifications from "layouts/pages/account/settings/components/Notifications";
// import Sessions from "layouts/pages/account/settings/components/Sessions";
// import DeleteAccount from "layouts/pages/account/settings/components/DeleteAccount";

function Settings() {
  return (
    <BaseLayout>
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Accounts />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default Settings;
