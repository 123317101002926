import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { PropTypes } from "prop-types";

export default function RequireAuth({ component: Component, allowedRole }) {
  const currentUser = useSelector((state) => state?.user?.currentUser?.data);
  console.log("allowedRole ==> ", allowedRole);
  if (allowedRole.includes(currentUser.user_type)) {
    return <Component />;
  }
  return <Navigate to="/unauthorized" />;
}

RequireAuth.propTypes = {
  component: PropTypes.elementType.isRequired,
  allowedRole: PropTypes.arrayOf(PropTypes.string).isRequired,
};
