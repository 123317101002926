/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AlbumService from "services/AlbumService";
import { useSnackbar } from "notistack";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDButton from "components/MDButton";

import { AiOutlineCloudUpload } from "react-icons/ai";
import { BsPlus } from "react-icons/bs";
import { BiMinus } from "react-icons/bi";

import FormInput from "./components/FormInput";
import FormSelect from "./components/FormSelect";
import FormTextarea from "./components/FormTextarea";
import FileUploader from "./components/FileUploader";
import validateForm from "./validateForm";

import "./assetForm.css";

const assetTypes = [
  {
    id: 1,
    name: "Music",
  },
  {
    id: 2,
    name: "Video",
  },
  {
    id: 3,
    name: "Art",
  },
  {
    id: 4,
    name: "Album",
  },
];

const genresData = [
  {
    id: 1,
    name: "Rock",
  },
  {
    id: 2,
    name: "Classical Music",
  },
  {
    id: 3,
    name: "Pop music",
  },
  {
    id: 4,
    name: "Hip Pop music",
  },
  {
    id: 5,
    name: "Jazz",
  },
];

function AssetForm() {
  const { enqueueSnackbar } = useSnackbar();

  const [values, setValues] = useState({
    type: "",
    albumName: "",
    author: "",
    cdn: "",
    genre_id: "",
    starting_price: "",
    isrc_code: "",
    record_lable_name: "",
    publisher: "",
    catalogue_number: "",
    composer: "",
    barcode: "",
    description: "",
    quantity: "",
    buy_count: "",
    share_percentage: "",
    revenue: "",
    release_date: "",
    series: "",
    format: "",
    country: "",
    style: "",
    credits: "",
    notes: "",
    copyright_id: "",
    minting_type: "white listed",
  });

  const [albumImage, setAlbumImage] = useState(null);
  const [albumImagePreview, setAlbumImagePreview] = useState(null);

  const [certificateImage, setCertificateImage] = useState(null);
  const [certificateImagePreview, setCertificateImagePreview] = useState(null);

  const [trackList, setTrackList] = useState([
    {
      track_name: "",
      track_cdn: "",
      isrc_code: "",
      composer: "",
      writer: "",
    },
  ]);
  const [showTrackList, setShowTrackList] = useState(false);

  const [assetTypeError, setAssetTypeError] = useState({
    isError: false,
    message: "",
  });
  const [genreError, setGenreError] = useState({
    isError: false,
    message: "",
  });
  const [albumNameError, setAlbumNameError] = useState({
    isError: false,
    message: "",
  });
  const [startingPriceError, setStartingPriceError] = useState({
    isError: false,
    message: "",
  });
  const [qtyError, setQtyError] = useState({
    isError: false,
    message: "",
  });

  const currentUser = useSelector((state) => state?.user?.currentUser?.data);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeAssetType = (e) => {
    console.log("asset type value : ", e.target.value);
    if (e.target.value === 4) {
      setShowTrackList(true);
      console.log("asset type value : ", e.target.value);
    } else {
      setShowTrackList(false);
    }
  };

  function resetForm() {
    setValues({
      type: "",
      albumName: "",
      author: "",
      genre_id: "",
      cdn: "",
      starting_price: "",
      isrc_code: "",
      record_lable_name: "",
      publisher: "",
      catalogue_number: "",
      composer: "",
      barcode: "",
      description: "",
      quantity: "",
      buy_count: "",
      share_percentage: "",
      revenue: "",
      release_date: "",
      series: "",
      format: "",
      country: "",
      style: "",
      credits: "",
      notes: "",
      copyright_id: "",
      minting_type: "white listed",
    });
    setAlbumImage(undefined);
    setCertificateImage(undefined);
  }

  function resetTracks() {
    setTrackList([
      {
        track_name: "",
        track_cdn: "",
        composer: "",
        writer: "",
        isrc_code: "",
      },
    ]);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validatedError = validateForm(
      values,
      setAssetTypeError,
      setGenreError,
      setAlbumNameError,
      setStartingPriceError,
      setQtyError
    );

    if (validatedError) {
      enqueueSnackbar("Failed, Please double check your input data", {
        variant: "error",
        autoHideDuration: 6000,
      });
    } else {
      try {
        const result = await AlbumService.saveAlbum(
          values,
          trackList,
          albumImage,
          certificateImage,
          currentUser?.user_id
        );
        if (result?.data.success === 1) {
          resetForm();
          resetTracks();
          console.log("reset done");
          enqueueSnackbar("Successfully Saved !", { variant: "success" });
        }
      } catch (error) {
        console.log(error);
        enqueueSnackbar("Failed to create !", { variant: "error" });
      }
    }
  };

  const handleAlbumUpload = (e) => {
    const albumArwork = e.target.files[0];
    if (albumArwork && albumArwork.type.substr(0, 5) === "image") {
      setAlbumImage(albumArwork);
    } else {
      setAlbumImage(undefined);
    }
  };

  const removeAlbumImage = () => {
    setAlbumImage(undefined);
  };

  const removeCertificateImage = () => {
    setCertificateImage(undefined);
  };

  const handleCertificationUpload = (e) => {
    const certificate = e.target.files[0];
    if (certificate && certificate.type.substr(0, 5) === "image") {
      setCertificateImage(certificate);
    } else {
      setCertificateImage(undefined);
    }
  };

  const addTrackList = () => {
    setTrackList([
      ...trackList,
      {
        track_name: "",
        track_cdn: "",
        composer: "",
        writer: "",
        isrc_code: "",
      },
    ]);
  };

  const removeTrackList = (index, e) => {
    e.preventDefault();
    const list = [...trackList];
    list.splice(index, 1);
    setTrackList(list);
  };

  const onChangeTrackName = (e, index) => {
    const { value } = e.target;
    const list = [...trackList];
    list[index].track_name = value;
    setTrackList(list);
  };

  const onChangeTrackComposer = (e, index) => {
    const { value } = e.target;
    const list = [...trackList];
    list[index].composer = value;
    setTrackList(list);
  };

  const onChangeTrackWriter = (e, index) => {
    const { value } = e.target;
    const list = [...trackList];
    list[index].writer = value;
    setTrackList(list);
  };

  const onChangeTrackISRC = (e, index) => {
    const { value } = e.target;
    const list = [...trackList];
    list[index].isrc_code = value;
    setTrackList(list);
  };

  const onChangeTrackCdn = (e, index) => {
    const { value } = e.target;
    const list = [...trackList];
    list[index].track_cdn = value;
    setTrackList(list);
  };

  useEffect(() => {
    if (albumImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAlbumImagePreview(reader.result);
      };
      reader.readAsDataURL(albumImage);
    } else {
      setAlbumImagePreview(undefined);
    }
  }, [albumImage]);

  useEffect(() => {
    if (certificateImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCertificateImagePreview(reader.result);
      };
      reader.readAsDataURL(certificateImage);
    } else {
      setCertificateImagePreview(undefined);
    }
  }, [certificateImage]);

  return (
    <MDBox>
      <MDTypography variant="h5">Create an IMO</MDTypography>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MDBox>
              <FormSelect
                key="type"
                id="type"
                name="type"
                label="Asset type"
                value={values.type}
                data={assetTypes}
                onChange={(e) => {
                  onChange(e);
                  onChangeAssetType(e);
                }}
                errorData={assetTypeError}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDBox>
              <FormSelect
                key="genre_id"
                id="genre_id"
                name="genre_id"
                label="Genre"
                value={values.genre_id}
                data={genresData}
                onChange={onChange}
                errorData={genreError}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox>
        {showTrackList && (
          <MDBox mt={3}>
            <div className="album-track-wrapper">
              <h3 className="asset-form-sub-title">Album Track information</h3>
              {trackList.map((track, index) => (
                <Grid container spacing={3} key={index}>
                  <Grid item xs={2}>
                    <FormInput
                      key="track_name"
                      id="track_name"
                      name="track_name"
                      type="text"
                      placeholder="Track name"
                      label="Track name"
                      value={track.track_name}
                      onChange={(e) => onChangeTrackName(e, index)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormInput
                      key="composer"
                      id="composer"
                      name="composer"
                      type="text"
                      placeholder="Composer"
                      label="Composer"
                      value={track.composer}
                      onChange={(e) => onChangeTrackComposer(e, index)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormInput
                      key="writer"
                      id="writer"
                      name="writer"
                      type="text"
                      placeholder="Writer"
                      label="Writer"
                      value={track.writer}
                      onChange={(e) => onChangeTrackWriter(e, index)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormInput
                      key="isrc"
                      id="isrc"
                      name="isrc"
                      type="text"
                      placeholder="ISRC Code"
                      label="ISRC Code"
                      value={track.isrc_code}
                      onChange={(e) => onChangeTrackISRC(e, index)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormInput
                      key="track_cdn"
                      id="track_cdn"
                      name="track_cdn"
                      type="text"
                      placeholder="Track CDN link"
                      label="Track CDN link"
                      value={track.track_cdn}
                      onChange={(e) => onChangeTrackCdn(e, index)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <div className="btn-wrapper">
                      {trackList.length > 1 && (
                        <MDButton className="remove" onClick={(e) => removeTrackList(index, e)}>
                          <BiMinus />
                        </MDButton>
                      )}
                      {trackList.length - 1 === index && (
                        <MDButton className="add" onClick={addTrackList}>
                          <BsPlus />
                        </MDButton>
                      )}
                    </div>
                  </Grid>
                </Grid>
              ))}
            </div>
          </MDBox>
        )}
      </MDBox>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormInput
              key="album-name"
              id="album-name"
              name="albumName"
              type="text"
              placeholder="Album Name"
              label="Album Name"
              value={values.albumName}
              onChange={onChange}
              errorData={albumNameError}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              key="author"
              id="author"
              name="author"
              type="text"
              placeholder="Artist name"
              label="Artist"
              value={values.author}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {certificateImagePreview ? (
              <div className="preview-image">
                <img src={certificateImagePreview} alt="im" />
                <div
                  className="reupload-btn"
                  onClick={removeCertificateImage}
                  role="button"
                  tabIndex={0}
                >
                  <span>
                    <AiOutlineCloudUpload className="cloud-icon" /> Repload asset
                  </span>
                </div>
              </div>
            ) : (
              <FileUploader
                type="file"
                name="certi_path"
                accept="image/*"
                onChangeFile={handleCertificationUpload}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {albumImagePreview ? (
              <div className="preview-image">
                <img src={albumImagePreview} alt="im" />
                <div className="reupload-btn" onClick={removeAlbumImage} role="button" tabIndex={0}>
                  <span>
                    <AiOutlineCloudUpload className="cloud-icon" /> Repload asset
                  </span>
                </div>
              </div>
            ) : (
              <FileUploader
                type="file"
                name="image_path"
                accept="image/*"
                onChangeFile={handleAlbumUpload}
              />
            )}
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormInput
              key="CDN"
              id="cdn"
              name="cdn"
              type="text"
              placeholder="Paste CDN link here"
              label="CDN Link"
              value={values.cdn}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={1}>
        <MDTypography variant="h6">Purchase information</MDTypography>
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormInput
                key="starting_price"
                id="starting_price"
                name="starting_price"
                type="text"
                placeholder="Purchase price"
                label="Original purchase price"
                value={values.starting_price}
                onChange={onChange}
                darkLabel={true}
                darkLabelValue="$"
                errorData={startingPriceError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInput
                key="isrc_code"
                id="isrc_code"
                name="isrc_code"
                type="text"
                placeholder="ISRC Code"
                label="ISRC Code"
                value={values.isrc_code}
                onChange={onChange}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox mt={1}>
        <MDTypography variant="h6">Album information</MDTypography>
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormInput
                key="record_lable_name"
                id="record_lable_name"
                name="record_lable_name"
                type="text"
                placeholder="Record label"
                label="Record label"
                value={values.record_lable_name}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInput
                key="publisher"
                id="publisher"
                name="publisher"
                type="text"
                placeholder="Publisher"
                label="Publisher"
                value={values.publisher}
                onChange={onChange}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormInput
                key="catalogue_number"
                id="catalogue_number"
                name="catalogue_number"
                type="text"
                placeholder="Catalogue number"
                label="Catalogue number"
                value={values.catalogue_number}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInput
                key="composer"
                id="composer"
                name="composer"
                type="text"
                placeholder="Composer"
                label="Composer"
                value={values.composer}
                onChange={onChange}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormInput
                key="release_date"
                id="release_date"
                name="release_date"
                type="text"
                placeholder="Release Date"
                label="Release Date"
                value={values.release_date}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInput
                key="series"
                id="series"
                name="series"
                type="text"
                placeholder="Series"
                label="Series"
                value={values.series}
                onChange={onChange}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormInput
                key="country"
                id="country"
                name="country"
                type="text"
                placeholder="Country"
                label="Country"
                value={values.country}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInput
                key="style"
                id="style"
                name="style"
                type="text"
                placeholder="Style"
                label="Style"
                value={values.style}
                onChange={onChange}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormInput
                key="format"
                id="format"
                name="format"
                type="text"
                placeholder="Format"
                label="Format"
                value={values.format}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInput
                key="credits"
                id="credits"
                name="credits"
                type="text"
                placeholder="Credits"
                label="Credits"
                value={values.credits}
                onChange={onChange}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormInput
                key="barcode"
                id="barcode"
                name="barcode"
                type="text"
                placeholder="Barcode"
                label="Barcode"
                value={values.barcode}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInput
                key="copyright_id"
                id="copyright_id"
                name="copyright_id"
                type="text"
                placeholder="Copyright Owner"
                label="Copyright Owner"
                value={values.copyright_id}
                onChange={onChange}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormTextarea
                key="notes"
                id="notes"
                name="notes"
                placeholder="Notes"
                label="Notes"
                rows="7"
                value={values.notes}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextarea
                key="description"
                id="description"
                name="description"
                placeholder="Album description"
                label="Album description"
                rows="7"
                value={values.description}
                onChange={onChange}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox mt={1}>
        <MDTypography variant="h6">Mint information</MDTypography>
        <MDBox mt={2}>
          <MDBox display="inline-block">
            <MDTypography
              component="label"
              variant="button"
              fontWeight="regular"
              color="text"
              textTransform="capitalize"
            >
              Mint Type
            </MDTypography>
          </MDBox>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value="white listed"
              control={<Radio />}
              label="24 hour white listed NFTs"
              checked={values.minting_type === "white listed"}
            />
            <FormControlLabel
              value="public"
              control={<Radio />}
              label="Public mint"
              checked={values.minting_type === "public"}
            />
          </RadioGroup>
        </MDBox>
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormInput
                key="buy_count"
                id="buy_count"
                name="buy_count"
                type="text"
                placeholder="Suggested current valuation"
                label="Suggested current valuation"
                value={values.buy_count}
                onChange={onChange}
                darkLabel={true}
                darkLabelValue="$"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInput
                key="quantity"
                id="quantity"
                name="quantity"
                type="text"
                placeholder="number of NFTs"
                label="Number of desired child NFTs"
                value={values.quantity}
                onChange={onChange}
                darkLabel={true}
                darkLabelValue="NFT"
                errorData={qtyError}
              />
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormInput
                key="share_percentage"
                id="share_percentage"
                name="share_percentage"
                type="text"
                placeholder="Share Percentage"
                label="Percentage to be given to the market"
                value={values.share_percentage}
                onChange={onChange}
                darkLabel={true}
                darkLabelValue="%"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInput
                key="revenue"
                id="revenue"
                name="revenue"
                type="text"
                placeholder="Revenue"
                label="Yearly album revenue"
                value={values.revenue}
                onChange={onChange}
                darkLabel={true}
                darkLabelValue="$"
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox mt={4}>
        <Grid container>
          <MDButton variant="contained" component="label" color="primary" onClick={handleSubmit}>
            Submit
          </MDButton>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default AssetForm;
