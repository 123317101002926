import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

function PrivateRoute() {
  const currentUser = useSelector((state) => state?.user?.currentUser?.data);
  // const token = null;
  if (!currentUser?.user_id) {
    return <Navigate to="/login" />;
  }
  return <Outlet />;
}

export default PrivateRoute;
