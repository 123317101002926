import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import dataTableColumns from "layouts/pages/users/all-user/data/dataTableColumns";
import UserService from "services/UserService";

import { ExportToCsv } from "export-to-csv";

const options = {
  filename: "user-data",
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: dataTableColumns.columns.map((c) => c.Header),
};

const csvExporter = new ExportToCsv(options);

function UserList() {
  const [menu, setMenu] = useState(null);
  const [dataTableData, setDataTableData] = useState({
    columns: dataTableColumns.columns,
    rows: [],
  });
  const [users, setUsers] = useState([]);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const getAdminUsers = () => {
    // eslint-disable-next-line arrow-body-style
    const filteredData = users.filter((data) => {
      return data.user_type === "admin";
    });
    setDataTableData({
      columns: dataTableColumns.columns,
      rows: filteredData,
    });
  };

  const getPaypalUsers = () => {
    // eslint-disable-next-line arrow-body-style
    const filteredData = users.filter((data) => {
      return data.paypal_login === 1;
    });
    setDataTableData({
      columns: dataTableColumns.columns,
      rows: filteredData,
    });
  };

  const exportToCSV = () => {
    // eslint-disable-next-line camelcase
    const modifiedExportData = dataTableData.rows.map(
      // eslint-disable-next-line camelcase
      ({ full_user_id, first_name, address, email, registered_at }) => {
        // eslint-disable-next-line camelcase
        const container = { full_user_id, first_name, address, email, registered_at };
        return container;
      }
    );

    csvExporter.generateCsv(modifiedExportData);
  };

  const getAllUsers = () => {
    UserService.getAllUsers()
      .then((response) => {
        const modifiedResult = response.data.data.map((item) => {
          const container = { ...item };
          container.full_user_id = `${container.user_id}`;
          container.user_id = `${container.user_id.slice(0, 6)}...`;
          container.first_name = `${container.first_name} ${container.last_name}`;
          return container;
        });

        setUsers(modifiedResult);

        setDataTableData({
          columns: dataTableColumns.columns,
          rows: modifiedResult,
        });
      })
      .catch((error) => {
        console.log("getAllUsers Error ", error);
      });
  };

  const removeFilter = () => {
    setDataTableData({
      columns: dataTableColumns.columns,
      rows: users,
    });
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={getAdminUsers}>Admin Users</MenuItem>
      <MenuItem onClick={getPaypalUsers}>Paypal Sign up</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular" onClick={removeFilter}>
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="end" alignItems="flex-start" mb={2}>
          <MDBox display="flex">
            <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark" onClick={exportToCSV}>
                <Icon>description</Icon>
                &nbsp; export csv
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <Card>
          <DataTable table={dataTableData} canSearch />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UserList;
