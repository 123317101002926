function AuthHeader() {
  const user = localStorage.getItem("persist:root");
  if (user != null) {
    const data = JSON.parse(JSON.parse(user).user);
    if (data.currentUser?.token) {
      return { Authorization: `Bearer ${data.currentUser.token}` };
    }
    return { Authorization: "" };
  }
}

function AuthHeaderWithMultipleForm() {
  const user = localStorage.getItem("persist:root");
  if (user != null) {
    const data = JSON.parse(JSON.parse(user).user);
    if (data.currentUser?.token) {
      return {
        Authorization: `Bearer ${data.currentUser.token}`,
        "Content-Type": "multipart/form-data",
      };
    }

    if (!data.currentUser?.token) {
      return { Authorization: "", "Content-Type": "multipart/form-data" };
    }
  }
}

const AuthHeaderService = {
  AuthHeader,
  AuthHeaderWithMultipleForm,
};

export default AuthHeaderService;
