import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import dataTableColumns from "layouts/pages/users/manual-kyc/data/dataTableColumns";
import UserService from "services/UserService";

import moment from "moment";

function UserList() {
  const [menu, setMenu] = useState(null);
  const [dataTableData, setDataTableData] = useState({
    columns: dataTableColumns.columns,
    rows: [],
  });

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const getManualKycUsers = () => {
    UserService.getManualKycUsers()
      .then((response) => {
        // console.log("getAllUsers ", response.data);

        const modifiedResult = response.data.data.map((item) => {
          const container = { ...item };
          container.user_id = `${container.user_id.slice(0, 6)}...`;
          container.created_at = moment(container.created_at).format("MMM d, YYYY");
          return container;
        });

        // eslint-disable-next-line arrow-body-style
        modifiedResult.sort((a, b) => {
          return moment(b.created_at).diff(a.created_at);
        });

        // console.log("orderByDate ==> ", orderByDate);

        setDataTableData({
          columns: dataTableColumns.columns,
          rows: modifiedResult,
        });
      })
      .catch((error) => {
        console.log("getManualKycUsers Error ", error);
      });
  };

  useEffect(() => {
    getManualKycUsers();
    // console.log("dataTableData Columns ", dataTableData.columns);
    // console.log("dataTableData Rows ", dataTableData.rows);
  }, []);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Status: Paid</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Refunded</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Canceled</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="end" alignItems="flex-start" mb={2}>
          <MDBox display="flex">
            <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <Card>
          <DataTable table={dataTableData} entriesPerPage={false} canSearch />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UserList;
