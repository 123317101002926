import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewUser page components
import UserInfo from "layouts/admin/new-user/components/UserInfo";
import Address from "layouts/admin/new-user/components/Address";
import Socials from "layouts/admin/new-user/components/Socials";
// import Profile from "layouts/admin/new-user/components/Profile";

// NewUser layout schemas for form and form feilds
import validations from "layouts/admin/new-user/schemas/validations";
import form from "layouts/admin/new-user/schemas/form";
import initialValues from "layouts/admin/new-user/schemas/initialValues";
// import UserService from "services/UserService";
import MDSnackbar from "components/MDSnackbar";

function getSteps() {
  return ["User Info", "Email", "Password"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <UserInfo formData={formData} />;
    case 1:
      return <Address formData={formData} />;
    case 2:
      return <Socials formData={formData} />;
    default:
      return null;
  }
}

function NewUser() {
  const [infoSB, setInfoSB] = useState(false);

  const [contmsg, SetMsg] = useState();

  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId2, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    await sleep(1000);

    // // eslint-disable-next-line no-alert
    // alert(JSON.stringify(values, null, 2));
    // console.log("----values-----", values);
    // actions.setSubmitting(false);
    // actions.resetForm();

    // setActiveStep(0);
    await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/users/createAdmin`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        password: values.password,
        user_type: "admin",
        email_verified: 1,
        status: 1,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          openInfoSB();
          SetMsg(data.data.message);
          actions.setSubmitting(false);
          actions.resetForm();
          setActiveStep(0);
        } else {
          console.log(data.message);
          console.log("----values error-----", values);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const renderInfoSB = (
    <MDSnackbar
      icon="notifications"
      title="RightsMint"
      content={contmsg}
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        {renderInfoSB}
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId2} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton variant="gradient" color="light" onClick={handleBack}>
                              back
                            </MDButton>
                          )}
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color={isLastStep ? "success" : "dark"}
                          >
                            {isLastStep ? "create account" : "next"}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewUser;
