/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from "react";
import PropTypes from "prop-types";

import "./formInput.css";

function FormInput({ label, errorData, onChange, id, darkLabel, darkLabelValue, ...inputProps }) {
  const [focused, setFocused] = useState(false);
  const handleFocus = () => {
    setFocused(true);
  };

  return (
    <div className="form-input-wrapper">
      <label className="form-input-label">{label}</label>
      <div className="form-input-inner">
        {darkLabel && <div className="dark-label">{darkLabelValue}</div>}
        <input
          className={`form-input ${darkLabel && "pl-60"} ${
            darkLabelValue === "NFT" ? "pl-80" : ""
          } ${errorData?.isError && "input-error"}`}
          {...inputProps}
          onChange={onChange}
          onBlur={handleFocus}
          onFocus={() => inputProps.name === "confirmPassword" && setFocused(true)}
          focused={focused.toString()}
        />
      </div>
      <span className="form-input-error">{errorData?.message}</span>
    </div>
  );
}

FormInput.propTypes = {
  label: PropTypes.string.isRequired,
  errorData: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  darkLabel: PropTypes.bool,
  darkLabelValue: PropTypes.string,
};

export default FormInput;
