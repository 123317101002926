/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
// ProductsList page components
// import IdCell from "layouts/ecommerce/orders/order-list/components/IdCell";
import DefaultCell from "layouts/admin/admin-list/components/DefaultCell";
import ActionCell from "layouts/admin/admin-list/components/ActionCell";

const dataTableColumns = {
  columns: [
    // { Header: "id", accessor: "user_id", Cell: ({ value }) => <IdCell id={value} /> },
    {
      Header: "Name",
      accessor: "first_name",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "email",
      accessor: "email",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "date",
      accessor: "created_at",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "user_id",
      Cell: (value) => <ActionCell value={value}
        />,
    },
  ],
};

export default dataTableColumns;
