/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
// import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
// import ProfileOverview from "layouts/pages/profile/profile-overview";
// import AllProjects from "layouts/pages/profile/all-projects";
import NewUser from "layouts/admin/new-user";
import AdminList from "layouts/admin/admin-list";
import Settings from "layouts/configs/settings";
import PPnTC from "layouts/configs/pp&tc";
// import Billing from "layouts/pages/account/billing";
// import Invoice from "layouts/pages/account/invoice";
// import Timeline from "layouts/pages/projects/timeline";
// import PricingPage from "layouts/pages/pricing-page";
// import Widgets from "layouts/pages/widgets";
// import RTL from "layouts/pages/rtl";
// import Charts from "layouts/pages/charts";
// import Notifications from "layouts/pages/notifications";
// import Kanban from "layouts/applications/kanban";
// import Wizard from "layouts/applications/wizard";
// import DataTables from "layouts/applications/data-tables";
// import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import UpdateProduct from "layouts/ecommerce/products/update-product";
// import EditProduct from "layouts/ecommerce/products/edit-product";
// import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import ProductList from "layouts/ecommerce/product/product-list";
// import EditProduct from "layouts/ecommerce/product/edit-product";
// import PurchaseList from "layouts/ecommerce/orders/all-purchases";
// import OrdersList from "layouts/pages/orders/all_orders";
import OrderDetails from "layouts/ecommerce/orders/order-details";
// import ProductDetails from "layouts/ecommerce/product/product-details";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInAdmin from "layouts/authentication/sign-in/admin";
// import SignInCover from "layouts/authentication/sign-in/cover";
// import SignInIllustration from "layouts/authentication/sign-in/illustration";
// import SignUpCover from "layouts/authentication/sign-up/cover";
// import ResetCover from "layouts/authentication/reset-password/cover";
import AllUser from "layouts/pages/users/all-user";
import ManualKyc from "layouts/pages/users/manual-kyc";
import ActiveCarts from "layouts/pages/users/active-cart";
import NotFound from "layouts/pages/404";
import Unauthorized from "layouts/pages/unauthorized";
import RequireAuth from "utils/RequireAuth";

// Material Dashboard 2 PRO React components
// import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
// import profilePicture from "assets/images/team-3.jpg";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboards/sales",
    component: <Sales />,
    icon: <Icon fontSize="medium">dashboard</Icon>,
    noCollapse: true,
    privateRoute: true,
    privateComponent: <RequireAuth component={Sales} allowedRole={["admin", "superAdmin"]} />,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="medium">person</Icon>,
    collapse: [
      {
        name: "All users",
        key: "all-users",
        route: "/users/all-users",
        component: <AllUser />,
        privateRoute: true,
        privateComponent: <RequireAuth component={AllUser} allowedRole={["admin", "superAdmin"]} />,
      },
      {
        name: "Manual KYC",
        key: "manual-kyc",
        route: "/users/manual-kyc",
        component: <ManualKyc />,
        privateRoute: true,
        privateComponent: (
          <RequireAuth component={ManualKyc} allowedRole={["admin", "superAdmin"]} />
        ),
      },
      {
        name: "Abandoned Carts",
        key: "abandoned-cart",
        route: "/users/abandoned-cart",
        component: <ActiveCarts />,
        privateRoute: true,
        privateComponent: (
          <RequireAuth component={ActiveCarts} allowedRole={["admin", "superAdmin"]} />
        ),
      },
    ],
  },
  {
    type: "collapse",
    name: "Order",
    key: "order",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [
      {
        name: "All Orders",
        key: "order-list",
        route: "/orders/order-list",
        privateRoute: true,
        privateComponent: (
          <RequireAuth component={OrderList} allowedRole={["admin", "superAdmin"]} />
        ),
      },
      {
        name: "Transfer popup",
        key: "transfer-popup",
        route: "/orders/transfer-popup",
        privateRoute: true,
        privateComponent: (
          <RequireAuth component={OrderDetails} allowedRole={["admin", "superAdmin"]} />
        ),
      },
    ],
  },
  {
    type: "collapse",
    name: "IMOs",
    key: "products",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "All IMOs",
        key: "imo-list",
        route: "/imo/imo-list",
        privateRoute: true,
        privateComponent: (
          <RequireAuth component={ProductList} allowedRole={["admin", "superAdmin"]} />
        ),
      },
      // {
      //   name: "IMO Details",
      //   key: "imo-details",
      //   route: "/imo/imo-details",
      //   privateRoute: true,
      //   privateComponent: <ProductDetails />,
      // },
      {
        name: "Add IMO",
        key: "add-imo",
        route: "/imo/new",
        privateRoute: true,
        privateComponent: (
          <RequireAuth component={NewProduct} allowedRole={["admin", "superAdmin"]} />
        ),
      },
      // {
      //   name: "Edit IMO",
      //   key: "edit-imo",
      //   route: "/imo/edit-imo",
      //   privateRoute: true,
      //   privateComponent: <EditProduct />,
      // },
    ],
  },
  {
    type: "collapse",
    name: "Config",
    key: "configs",
    icon: <Icon fontSize="medium">build</Icon>,
    collapse: [
      {
        name: "Panic Buttons",
        key: "panic-buttons",
        route: "/configs/panic-buttons",
        privateRoute: true,
        privateComponent: <RequireAuth component={Settings} allowedRole={["superAdmin"]} />,
      },
      {
        name: "PP & TC",
        key: "pp&tc",
        route: "/configs/pp&tc",
        privateRoute: true,
        privateComponent: <RequireAuth component={PPnTC} allowedRole={["superAdmin"]} />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Admin",
    key: "admins",
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    collapse: [
      {
        name: "Add Admin",
        key: "add-admin",
        route: "/admin/new-user",
        privateRoute: true,
        privateComponent: <RequireAuth component={NewUser} allowedRole={["superAdmin"]} />,
      },
      {
        name: "Admin List",
        key: "list-admin",
        route: "/admin/admin-list",
        privateRoute: true,
        privateComponent: <RequireAuth component={AdminList} allowedRole={["superAdmin"]} />,
      },
    ],
  },
  // { type: "title", title: "Pages", key: "title-pages" },
  // {
  //   type: "collapse",
  //   name: "Pages",
  //   key: "pages",
  //   icon: <Icon fontSize="medium">image</Icon>,
  //   collapse: [
  //     {
  //       name: "Profile",
  //       key: "profile",
  //       collapse: [
  //         {
  //           name: "Profile Overview",
  //           key: "profile-overview",
  //           route: "/pages/profile/profile-overview",
  //           component: <ProfileOverview />,
  //         },
  //         {
  //           name: "All Projects",
  //           key: "all-projects",
  //           route: "/pages/profile/all-projects",
  //           component: <AllProjects />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Users",
  //       key: "users",
  //       collapse: [
  //         {
  //           name: "New User",
  //           key: "new-user",
  //           route: "/pages/users/new-user",
  //           component: <NewUser />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Account",
  //       key: "account",
  //       collapse: [
  //         {
  //           name: "Settings",
  //           key: "settings",
  //           route: "/pages/account/settings",
  //           component: <Settings />,
  //         },
  //         {
  //           name: "Billing",
  //           key: "billing",
  //           route: "/pages/account/billing",
  //           component: <Billing />,
  //         },
  //         {
  //           name: "Invoice",
  //           key: "invoice",
  //           route: "/pages/account/invoice",
  //           component: <Invoice />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Projects",
  //       key: "projects",
  //       collapse: [
  //         {
  //           name: "Timeline",
  //           key: "timeline",
  //           route: "/pages/projects/timeline",
  //           component: <Timeline />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Pricing Page",
  //       key: "pricing-page",
  //       route: "/pages/pricing-page",
  //       component: <PricingPage />,
  //     },
  //     { name: "RTL", key: "rtl", route: "/pages/rtl", component: <RTL /> },
  //     { name: "Widgets", key: "widgets", route: "/pages/widgets", component: <Widgets /> },
  //     { name: "Charts", key: "charts", route: "/pages/charts", component: <Charts /> },
  //     {
  //       name: "Notfications",
  //       key: "notifications",
  //       route: "/pages/notifications",
  //       component: <Notifications />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Applications",
  //   key: "applications",
  //   icon: <Icon fontSize="medium">apps</Icon>,
  //   collapse: [
  //     {
  //       name: "Kanban",
  //       key: "kanban",
  //       route: "/applications/kanban",
  //       component: <Kanban />,
  //     },
  //     {
  //       name: "Wizard",
  //       key: "wizard",
  //       route: "/applications/wizard",
  //       component: <Wizard />,
  //     },
  //     {
  //       name: "Data Tables",
  //       key: "data-tables",
  //       route: "/applications/data-tables",
  //       component: <DataTables />,
  //     },
  //     {
  //       name: "Calendar",
  //       key: "calendar",
  //       route: "/applications/calendar",
  //       component: <Calendar />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Ecommerce",
  //   key: "ecommerce",
  //   icon: <Icon fontSize="medium">shopping_basket</Icon>,
  //   collapse: [
  //     {
  //       name: "Products",
  //       key: "products",
  //       collapse: [
  //         {
  //           name: "New Product",
  //           key: "new-product",
  //           route: "/ecommerce/products/new-product",
  //           component: <NewProduct />,
  //         },
  //         {
  //           name: "Edit Product",
  //           key: "edit-product",
  //           route: "/ecommerce/products/edit-product",
  //           component: <EditProduct />,
  //         },
  //         {
  //           name: "Product Page",
  //           key: "product-page",
  //           route: "/ecommerce/products/product-page",
  //           component: <ProductPage />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    type: "invisible",
    name: "Login",
    key: "login",
    route: "/login",
    component: <SignInBasic />,
    icon: <Icon fontSize="medium">content_paste</Icon>,
    noCollapse: true,
  },
  {
    type: "invisible",
    name: "Login",
    key: "login",
    route: "/login/admin/super",
    component: <SignInAdmin />,
    icon: <Icon fontSize="medium">content_paste</Icon>,
    noCollapse: true,
  },
  {
    type: "invisible",
    name: "NotFound",
    key: "not-found",
    route: "/not-found",
    component: <NotFound />,
    icon: <Icon fontSize="medium">content_paste</Icon>,
    noCollapse: true,
  },
  {
    type: "invisible",
    name: "Unauthorized",
    key: "unauthorized",
    route: "/unauthorized",
    component: <Unauthorized />,
    icon: <Icon fontSize="medium">content_paste</Icon>,
    noCollapse: true,
  },
  {
    type: "invisible",
    name: "UpdateIMO",
    key: "update-imo",
    route: "/update/imo/:asset_id",
    privateComponent: <UpdateProduct />,
    noCollapse: true,
    privateRoute: true,
  },
  // { type: "divider", key: "divider-1" },
  // { type: "title", title: "Docs", key: "title-docs" },
  // {
  //   type: "collapse",
  //   name: "Basic",
  //   key: "basic",
  //   icon: <Icon fontSize="medium">upcoming</Icon>,
  //   collapse: [
  //     {
  //       name: "Getting Started",
  //       key: "getting-started",
  //       collapse: [
  //         {
  //           name: "Overview",
  //           key: "overview",
  //           href: "https://www.creative-tim.com/learning-lab/react/overview/material-dashboard/",
  //         },
  //         {
  //           name: "License",
  //           key: "license",
  //           href: "https://www.creative-tim.com/learning-lab/react/license/material-dashboard/",
  //         },
  //         {
  //           name: "Quick Start",
  //           key: "quick-start",
  //           href: "https://www.creative-tim.com/learning-lab/react/quick-start/material-dashboard/",
  //         },
  //         {
  //           name: "Build Tools",
  //           key: "build-tools",
  //           href: "https://www.creative-tim.com/learning-lab/react/build-tools/material-dashboard/",
  //         },
  //       ],
  //     },
  //     {
  //       name: "Foundation",
  //       key: "foundation",
  //       collapse: [
  //         {
  //           name: "Colors",
  //           key: "colors",
  //           href: "https://www.creative-tim.com/learning-lab/react/colors/material-dashboard/",
  //         },
  //         {
  //           name: "Grid",
  //           key: "grid",
  //           href: "https://www.creative-tim.com/learning-lab/react/grid/material-dashboard/",
  //         },
  //         {
  //           name: "Typography",
  //           key: "base-typography",
  //           href: "https://www.creative-tim.com/learning-lab/react/base-typography/material-dashboard/",
  //         },
  //         {
  //           name: "Borders",
  //           key: "borders",
  //           href: "https://www.creative-tim.com/learning-lab/react/borders/material-dashboard/",
  //         },
  //         {
  //           name: "Box Shadows",
  //           key: "box-shadows",
  //           href: "https://www.creative-tim.com/learning-lab/react/box-shadows/material-dashboard/",
  //         },
  //         {
  //           name: "Functions",
  //           key: "functions",
  //           href: "https://www.creative-tim.com/learning-lab/react/functions/material-dashboard/",
  //         },
  //         {
  //           name: "Routing System",
  //           key: "routing-system",
  //           href: "https://www.creative-tim.com/learning-lab/react/routing-system/material-dashboard/",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Components",
  //   key: "components",
  //   icon: <Icon fontSize="medium">view_in_ar</Icon>,
  //   collapse: [
  //     {
  //       name: "Alerts",
  //       key: "alerts",
  //       href: "https://www.creative-tim.com/learning-lab/react/alerts/material-dashboard/",
  //     },
  //     {
  //       name: "Avatar",
  //       key: "avatar",
  //       href: "https://www.creative-tim.com/learning-lab/react/avatar/material-dashboard/",
  //     },
  //     {
  //       name: "Badge",
  //       key: "badge",
  //       href: "https://www.creative-tim.com/learning-lab/react/badge/material-dashboard/",
  //     },
  //     {
  //       name: "Badge Dot",
  //       key: "badge-dot",
  //       href: "https://www.creative-tim.com/learning-lab/react/badge-dot/material-dashboard/",
  //     },
  //     {
  //       name: "Box",
  //       key: "box",
  //       href: "https://www.creative-tim.com/learning-lab/react/box/material-dashboard/",
  //     },
  //     {
  //       name: "Buttons",
  //       key: "buttons",
  //       href: "https://www.creative-tim.com/learning-lab/react/buttons/material-dashboard/",
  //     },
  //     {
  //       name: "Date Picker",
  //       key: "date-picker",
  //       href: "https://www.creative-tim.com/learning-lab/react/datepicker/material-dashboard/",
  //     },
  //     {
  //       name: "Dropzone",
  //       key: "dropzone",
  //       href: "https://www.creative-tim.com/learning-lab/react/dropzone/material-dashboard/",
  //     },
  //     {
  //       name: "Editor",
  //       key: "editor",
  //       href: "https://www.creative-tim.com/learning-lab/react/quill/material-dashboard/",
  //     },
  //     {
  //       name: "Input",
  //       key: "input",
  //       href: "https://www.creative-tim.com/learning-lab/react/input/material-dashboard/",
  //     },
  //     {
  //       name: "Pagination",
  //       key: "pagination",
  //       href: "https://www.creative-tim.com/learning-lab/react/pagination/material-dashboard/",
  //     },
  //     {
  //       name: "Progress",
  //       key: "progress",
  //       href: "https://www.creative-tim.com/learning-lab/react/progress/material-dashboard/",
  //     },
  //     {
  //       name: "Snackbar",
  //       key: "snackbar",
  //       href: "https://www.creative-tim.com/learning-lab/react/snackbar/material-dashboard/",
  //     },
  //     {
  //       name: "Social Button",
  //       key: "social-button",
  //       href: "https://www.creative-tim.com/learning-lab/react/social-buttons/material-dashboard/",
  //     },
  //     {
  //       name: "Typography",
  //       key: "typography",
  //       href: "https://www.creative-tim.com/learning-lab/react/typography/material-dashboard/",
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Change Log",
  //   key: "changelog",
  //   href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
  //   icon: <Icon fontSize="medium">receipt_long</Icon>,
  //   noCollapse: true,
  // },
];

export default routes;
