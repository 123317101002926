/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { FaImage } from "react-icons/fa";
import PropTypes from "prop-types";
import "./fileUploader.css";

function FileUploader({ onChangeFile, errorStatus, ...inputProps }) {
  return (
    <div className="file-upload-wrapper">
      <label className="file-label">Album artwork</label>
      <div className={errorStatus ? "file-content file-upload-error-border" : "file-content"}>
        <input {...inputProps} onChange={onChangeFile} />
        <FaImage className="image-icon" />
        <p className="file-text">
          Drag and drop or <span className="file-span">Browse</span>
        </p>
        <h5>Upload album cover for this album</h5>
        <h6>File types supported are PNG and JPEG</h6>
      </div>

      {errorStatus && <p style={{ color: "red" }}>Please fill out this form</p>}
    </div>
  );
}

FileUploader.propTypes = {
  onChangeFile: PropTypes.func.isRequired,
  errorStatus: PropTypes.bool,
};

export default FileUploader;
