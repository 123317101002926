/* eslint-disable prettier/prettier */
import checkout from "layouts/admin/new-user/schemas/form";

const {
  formField: {
    firstName,
    lastName,
    email,
    password,
    repeatPassword,
  },
} = checkout;

const initialValues = {
  [firstName.name]: "",
  [lastName.name]: "",
  [email.name]: "",
  [password.name]: "",
  [repeatPassword.name]: "",
};

export default initialValues;
