const validateForm = (
  formData,
  setAssetTypeError,
  setGenreError,
  setAlbumNameError,
  setStartingPriceError,
  setQtyError
) => {
  let error = false;
  if (formData.type === "") {
    setAssetTypeError({ isError: true, message: "Please select the asset type" });
    error = true;
  } else {
    setAssetTypeError({ isError: false, message: "" });
  }
  if (formData.genre_id === "") {
    setGenreError({ isError: true, message: "Please select the genre" });
    error = true;
  } else {
    setGenreError({ isError: false, message: "" });
  }
  if (formData.albumName === "") {
    setAlbumNameError({ isError: true, message: "Album name is required" });
    error = true;
  } else {
    setAlbumNameError({ isError: false, message: "" });
  }
  if (formData.starting_price === "") {
    setStartingPriceError({ isError: true, message: "Purchase price is required" });
    error = true;
  } else {
    setStartingPriceError({ isError: false, message: "" });
  }
  if (formData.quantity === "") {
    setQtyError({ isError: true, message: "Number of NFTs is required" });
    error = true;
  } else {
    setQtyError({ isError: false, message: "" });
  }
  return error;
};

export default validateForm;
