/* eslint-disable camelcase */
import AuthHeaderService from "utils/AuthHeader";
import axios from "axios";

class AssetService {
  static async getAllAssets() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/assets`,
      headers: AuthHeaderService.AuthHeader(),
    });
    return result;
  }

  static async saveAsset(albumId, values, trackList, albumImage, certificateImage, user_id) {
    const formData = new FormData();
    formData.append("name", values.albumName);
    formData.append("album_id", albumId);
    formData.append("author", values.author);
    formData.append("revenue", values.revenue);
    formData.append("owner_id", user_id);
    formData.append("sku_id", "0");
    formData.append("contract_id", "0");
    formData.append("description", values.description);
    formData.append("genre_id", values.genre_id);
    formData.append("quantity", values.quantity);
    formData.append("type", values.type);
    formData.append("share_percentage", values.share_percentage);
    formData.append("starting_price", values.starting_price);
    formData.append("buy_count", values.buy_count);
    formData.append("record_lable_name", values.record_lable_name);
    formData.append("isrc_code", values.isrc_code);
    formData.append("publisher", values.publisher);
    formData.append("composer", values.composer);
    formData.append("catalogue_number", values.catalogue_number);
    formData.append("barcode", values.barcode);
    formData.append("minting_type", values.minting_type);
    formData.append("release_date", values.release_date);
    formData.append("series", values.series);
    formData.append("format", values.format);
    formData.append("country", values.country);
    formData.append("style", values.style);
    formData.append("credits", values.credits);
    formData.append("notes", values.notes);
    formData.append("copyright_id", values.copyright_id);
    formData.append("image", albumImage);
    formData.append("copyright_certificate", certificateImage);
    formData.append("trackList", JSON.stringify(trackList));

    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/assets`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
    console.log("Asset created --- ", response);
    return response;
  }

  static async updateAsset(asset_id, values, trackList, albumImage, certificateImage) {
    const formData = new FormData();

    if (albumImage) {
      formData.append("image", albumImage);
    } else {
      formData.append("image", values.album_image);
    }

    if (certificateImage) {
      formData.append("copyright_certificate", certificateImage);
    } else {
      formData.append("copyright_certificate", values.copyright_certificate_image);
    }

    formData.append("name", values.albumName);
    formData.append("album_id", values.album_id);
    formData.append("author", values.author);
    formData.append("revenue", values.revenue);
    formData.append("description", values.description);
    formData.append("genre_id", values.genre_id);
    formData.append("quantity", values.quantity);
    formData.append("type", values.type);
    formData.append("share_percentage", values.share_percentage);
    formData.append("starting_price", values.starting_price);
    formData.append("buy_count", values.buy_count);
    formData.append("record_lable_name", values.record_lable_name);
    formData.append("isrc_code", values.isrc_code);
    formData.append("publisher", values.publisher);
    formData.append("composer", values.composer);
    formData.append("catalogue_number", values.catalogue_number);
    formData.append("barcode", values.barcode);
    formData.append("minting_type", values.minting_type);
    formData.append("valuation", values.valuation);
    formData.append("collection_id", values.collection_id);
    formData.append("external_url", values.external_url);
    formData.append("animation_url", values.animation_url);
    formData.append("contract_address", values.contract_address);
    formData.append("owner_id", values.owner_id);
    formData.append("copyright_id", values.copyright_id);
    formData.append("transaction_fee", values.transaction_fee);
    formData.append("status", values.status);
    formData.append("sku_id", values.sku_id);
    formData.append("contract_id", values.contract_id);
    formData.append("config_id", values.config_id);
    formData.append("cdn", values.cdn);
    formData.append("asset_id", asset_id);
    formData.append("release_date", values.release_date);
    formData.append("series", values.series);
    formData.append("country", values.country);
    formData.append("style", values.style);
    formData.append("format", values.format);
    formData.append("credits", values.credits);
    formData.append("notes", values.notes);
    formData.append("temp_asset_id", values.temp_asset_id);

    formData.append("trackList", JSON.stringify(trackList));

    const response = await axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/assets/update`,
      data: formData,
      headers: AuthHeaderService.AuthHeaderWithMultipleForm(),
    });
    return response;
  }

  static async getAssetByAssetID(assetId) {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/assets/${assetId}`,
      headers: AuthHeaderService.AuthHeader(),
    });
    return response;
  }

  static async getTracksByID(assetId) {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/assets/trackList/${assetId}`,
      headers: AuthHeaderService.AuthHeader(),
    });
    return response;
  }
}

export default AssetService;
