import AuthHeaderService from "utils/AuthHeader";
import axios from "axios";

class UserService {
  static async logOutUser() {
    const result = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/users/logout`,
      headers: AuthHeaderService.AuthHeader(),
    });

    return result;
  }

  static async getAllAdmins() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/users/admin-list`,
      headers: AuthHeaderService.AuthHeader(),
    });
    return result;
  }

  static async getAllUsers() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/users`,
      headers: AuthHeaderService.AuthHeader(),
    });
    return result;
  }

  static async getTotalCount() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/users/count/all`,
      headers: AuthHeaderService.AuthHeader(),
    });
    return result;
  }

  static async getTotalCountByToday() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/users/count/today`,
      headers: AuthHeaderService.AuthHeader(),
    });
    return result;
  }

  static async getTotalCountByLastWeek() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/users/count/last-week`,
      headers: AuthHeaderService.AuthHeader(),
    });
    return result;
  }

  static async getTotalCountByLastMonth() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/users/count/last-month`,
      headers: AuthHeaderService.AuthHeader(),
    });
    return result;
  }

  static async getActiveCarts() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/cart/usersactivecart`,
      headers: AuthHeaderService.AuthHeader(),
    });
    return result;
  }

  static async getManualKycUsers() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/manual_kyc/getManualKycList`,
    });
    return result;
  }

  static async deleteAdmin(data) {
    const result = await axios({
      method: "patch",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/users/delete-admin`,
      data,
    });

    return result;
  }
}

export default UserService;
