/* eslint-disable camelcase */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import dataTableColumns from "layouts/ecommerce/orders/order-list/data/dataTableData";
import OrderService from "services/OrderService";

import { ExportToCsv } from "export-to-csv";

const options = {
  filename: "order-data",
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: dataTableColumns.columns.map((c) => c.Header),
};

const csvExporter = new ExportToCsv(options);

function OrderList() {
  const [menu, setMenu] = useState(null);
  const [dataTableData, setDataTableData] = useState({
    columns: dataTableColumns.columns,
    rows: [],
  });
  const [orders, setOrders] = useState([]);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const getModifiedData = (data) => {
    const modifiedResult = data.map((item) => {
      const container = { ...item };

      container.first_name = `${container.first_name} ${container.last_name}`;
      container.total_price = `$${container.total_price}`;

      return container;
    });
    return modifiedResult;
  };

  const exportToCSV = () => {
    const modifiedExportData = dataTableData.rows.map(
      ({
        transaction_id,
        purchased_date,
        nft_status,
        first_name,
        name,
        no_of_share,
        total_price,
      }) => {
        const container = {
          transaction_id,
          purchased_date,
          nft_status,
          first_name,
          name,
          no_of_share,
          total_price,
        };
        return container;
      }
    );

    csvExporter.generateCsv(modifiedExportData);
  };

  const getNftOrders = () => {
    OrderService.getAllNftOrders()
      .then((response) => {
        setOrders(getModifiedData(response.data.data));

        setDataTableData({
          columns: dataTableColumns.columns,
          rows: getModifiedData(response.data.data),
        });
      })
      .catch((error) => {
        console.log("getNftOrders error ", error);
      });
  };

  const getTransferedOrders = () => {
    // eslint-disable-next-line arrow-body-style
    const filteredData = orders.filter((data) => {
      return data.nft_status === "COMPLETED";
    });
    setDataTableData({
      columns: dataTableColumns.columns,
      rows: filteredData,
    });
  };

  const getPendingOrders = () => {
    // eslint-disable-next-line arrow-body-style
    const filteredData = orders.filter((data) => {
      return data.nft_status === "PENDING";
    });
    setDataTableData({
      columns: dataTableColumns.columns,
      rows: filteredData,
    });
  };

  const getNftOrdersByToday = () => {
    OrderService.getAllNftOrdersByToday()
      .then((response) => {
        setDataTableData({
          columns: dataTableColumns.columns,
          rows: getModifiedData(response.data.data),
        });
      })
      .catch((error) => {
        console.log("getNftOrdersByToday error ", error);
      });
  };

  const getNftOrdersByLastWeek = () => {
    OrderService.getAllNftOrdersByLastWeek()
      .then((response) => {
        setDataTableData({
          columns: dataTableColumns.columns,
          rows: getModifiedData(response.data.data),
        });
      })
      .catch((error) => {
        console.log("getNftOrdersByLastWeek error ", error);
      });
  };

  const removeFilter = () => {
    setDataTableData({
      columns: dataTableColumns.columns,
      rows: orders,
    });
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={getTransferedOrders}>Status: Transfered</MenuItem>
      <MenuItem onClick={getPendingOrders}>Status: Pending</MenuItem>
      <MenuItem onClick={getNftOrdersByToday}>Orders: Today</MenuItem>
      <MenuItem onClick={getNftOrdersByLastWeek}>Orders: Last 7 days</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={removeFilter}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    getNftOrders();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="end" alignItems="flex-start" mb={2}>
          <MDBox display="flex">
            <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark" onClick={exportToCSV}>
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <Card>
          <DataTable table={dataTableData} canSearch />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default OrderList;
