/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
// import Stepper from "@mui/material/Stepper";
// import Step from "@mui/material/Step";
// import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewProduct page components
// import ProductInfo from "layouts/ecommerce/products/new-product/components/ProductInfo";
// import Media from "layouts/ecommerce/products/new-product/components/Media";
// import Socials from "layouts/ecommerce/products/new-product/components/Socials";
// import Pricing from "layouts/ecommerce/products/new-product/components/Pricing";
import AssetForm from "layouts/ecommerce/products/new-product/components/AssetForm";

// function getSteps() {
//   return ["1. Product Info", "2. Media", "3. Social", "4. Pricing"];
// }

// function getStepContent(stepIndex) {
//   switch (stepIndex) {
//     case 0:
//       return <ProductInfo />;
//     case 1:
//       return <Media />;
//     case 2:
//       return <Socials />;
//     case 3:
//       return <Pricing />;
//     default:
//       return null;
//   }
// }

function NewProduct() {
  // const [activeStep, setActiveStep] = useState(0);
  // const steps = getSteps();
  // const isLastStep = activeStep === steps.length - 1;

  // const handleNext = () => setActiveStep(activeStep + 1);
  // const handleBack = () => setActiveStep(activeStep - 1);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Card>
              <MDBox p={4}>
                <AssetForm />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewProduct;
