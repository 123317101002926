import AuthHeaderService from "utils/AuthHeader";
import axios from "axios";
import AssetService from "./AssetService";

class AlbumService {
  static async getAllAssets() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/assets`,
      headers: AuthHeaderService.AuthHeader(),
    });
    return result;
  }

  static async saveAlbum(values, trackList, albumImage, certificateImage, userId) {
    const formData = new FormData();

    formData.append("title", values.albumName);
    formData.append("author", values.author);
    formData.append("description", values.description);
    formData.append("image_path", albumImage);

    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/albums`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
    console.log(" Album created --- ", response);
    const albumId = response.data.data.insertId;
    const result = await AssetService.saveAsset(
      albumId,
      values,
      trackList,
      albumImage,
      certificateImage,
      userId
    );
    return result;
  }
}

export default AlbumService;
