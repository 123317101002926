/* eslint-disable prettier/prettier */
import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// import Autocomplete from "@mui/material/Autocomplete";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDButton from "components/MDButton";
// import MDInput from "components/MDInput";
import ConfigService from "services/ConfigService";
import MDSnackbar from "components/MDSnackbar";
// NewProduct page components
// import FormField from "layouts/ecommerce/products/new-product/components/FormField";

function PPnTC() {
    const [privacyValue, setPrivacyValue] = useState();

    const [termsValue, setTermsValue] = useState();

    const [infoSB, setInfoSB] = useState(false);

    const [contmsg, SetMsg] = useState();
  
    const openInfoSB = () => setInfoSB(true);
    const closeInfoSB = () => setInfoSB(false);

    const submitPrivacy = (e) => {
        e.preventDefault();

        try {
            const data = {
                name: "privacy-policy",
                value: privacyValue,
            };
            ConfigService.UpdatePrivacy(data)
                .then((response) => {
                    console.log('privacy', response);
                    window.scrollTo(0, 0);
                    openInfoSB();
                    SetMsg(response.data.message);
                    //   enqueueSnackbar(response.data.message, { variant: "success" });
                })
                .catch((error) => {
                    console.log(error);
                    openInfoSB();
                    SetMsg(error);
                });
        } catch (error) {
            console.log(error);
        }
    };

    const submitTerms = (e) => {
        e.preventDefault();

        try {
            const data = {
                name: "terms",
                value: termsValue,
            };
            ConfigService.UpdateTerms(data)
                .then((response) => {
                    console.log('terms', response);
                    window.scrollTo(0, 0);
                    openInfoSB();
                    SetMsg(response.data.message);
                    //   enqueueSnackbar(response.data.message, { variant: "success" });
                })
                .catch((error) => {
                    console.log(error);
                    openInfoSB();
                    SetMsg(error);
                });
        } catch (error) {
            console.log(error);
        }
    };

    const GetPrivacy = () => {
        ConfigService.getPrivacyPolicy()
            .then((response) => {
                setPrivacyValue(response.data.data[0].value);
            })
            .catch((error) => {
                console.log("getPrivacyPolicy Error ", error);
            });
    };

    const GetTerms = () => {
        ConfigService.getTerms()
            .then((response) => {
                setTermsValue(response.data.data[0].value);
            })
            .catch((error) => {
                console.log("getTerms Error ", error);
            });
    };


    useEffect(() => {
        GetPrivacy();
        GetTerms();
    }, []);

    const renderInfoSB = (
        <MDSnackbar
          icon="notifications"
          title="RightsMint"
          content={contmsg}
          open={infoSB}
          onClose={closeInfoSB}
          close={closeInfoSB}
        />
      );

    return (
        <BaseLayout>
            <MDBox>
                <Card mb={2}>
                    <MDBox p={3}>
                        <Grid container spacing={3}>
                        {renderInfoSB}
                            <Grid item xs={12}>
                                <MDBox>
                                    <MDTypography variant="h5">Privacy Policy</MDTypography>
                                    <form>
                                    <MDBox mt={2}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12}>
                                                <MDEditor
                                                    value={privacyValue}
                                                    rows={20}
                                                    onChange={setPrivacyValue} />
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                    <MDBox display="flex" mt={3} justifyContent="flex-start">
                                        <MDButton variant="gradient" color="info" onClick={submitPrivacy}>
                                            SAVE
                                        </MDButton>
                                    </MDBox>
                                </form>
                            </MDBox>
                        </Grid>
                    </Grid>
            </MDBox>
        </Card>
            </MDBox >

        <MDBox mt={4} mb={3}>
            <Card mt={5}>
                <MDBox p={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <MDBox>
                                <MDTypography variant="h5">Terms of Service</MDTypography>

                                <MDBox mt={2}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12}>
                                            <MDEditor
                                                value={termsValue}
                                                onChange={setTermsValue} />
                                        </Grid>
                                    </Grid>
                                </MDBox>
                                <MDBox display="flex" mt={3} justifyContent="flex-start">
                                    <MDButton variant="gradient" color="info" onClick={submitTerms}>
                                        SAVE
                                    </MDButton>
                                </MDBox>
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>
            </Card>
        </MDBox>
        </BaseLayout >
    );
}

export default PPnTC;
