/* eslint-disable prettier/prettier */
// import AuthHeader from "utils/AuthHeader";
import axios from "axios";

class ConfigServices {
  static async getPrivacyPolicy() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/settings/privacy`,
    });
    return result;
  }

  static async UpdatePrivacy(data) {
    const result = await axios({
      method: "patch",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/settings/privacy`,
      data,
    });
    return result;
  }

  static async getTerms() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/settings/terms`,
    });
    return result;
  }

  static async UpdateTerms(data) {
    const result = await axios({
      method: "patch",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/settings/terms`,
      data,
    });
    return result;
  }

  static async getLoginBtnStatus() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/settings/loginButtonStatus`,
    });
    return result;
  }

  static async UpdateLoginButtonStatus(data) {
    const result = await axios({
      method: "patch",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/settings/loginButtonStatus`,
      data,
    });
    return result;
  }

  static async getCountdownStatus() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/settings/countdown`,
    });
    return result;
  }

  static async UpdateCountdownStatus(data) {
    const result = await axios({
      method: "patch",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/settings/countdown`,
      data,
    });
    return result;
  }

  static async getAddtoCartStatus() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/settings/mint-status`,
    });
    return result;
  }

  static async UpdateAddtoCartStatus(data) {
    const result = await axios({
      method: "patch",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/settings/mint-status`,
      data,
    });
    return result;
  }

  static async getDiscordStatus() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/settings/discord-status`,
    });
    return result;
  }

  static async UpdateDiscordStatus(data) {
    const result = await axios({
      method: "patch",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/settings/discord-status`,
      data,
    });
    return result;
  }

  static async getPaypalLoginStatus() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/settings/paypal-login-status`,
    });
    return result;
  }

  static async UpdatePaypalLoginStatus(data) {
    const result = await axios({
      method: "patch",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/settings/paypal-login-status`,
      data,
    });
    return result;
  }
}
export default ConfigServices;
