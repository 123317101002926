/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
// import MDTypography from "components/MDTypography";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
// import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";

function ActionCell({ value }) {
  const navigate = useNavigate();
  const handleClick = (assetId) => {
    navigate(`/update/imo/${assetId}`);
  };

  return (
    <MDBox
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width={{ xs: "100%", sm: "auto" }}
      mt={{ xs: 1, sm: 0 }}
    >
      <MDBox mr={1}>
        <MDButton
          variant="outlined"
          fontWeight="medium"
          color="info"
          onClick={() => handleClick(value)}
        >
          <Icon>edit</Icon>
        </MDButton>
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the DefaultCell
ActionCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default ActionCell;
