import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";
// import Switch from "@mui/material/Switch";
// import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import paypal from "assets/images/small-logos/paypal.svg";
import discordss from "assets/images/small-logos/discord.svg";
import ConfigService from "services/ConfigService";
// import MDAlert from "components/MDAlert";
import MDSnackbar from "components/MDSnackbar";

function Accounts() {
  const [setLogin, SetLogin] = useState();
  const [setPaypal, SetPaypal] = useState();
  const [cart, SetCart] = useState();
  const [discord, SetDiscord] = useState();
  const [setCountdown, SetCountdown] = useState();
  const [infoSB, setInfoSB] = useState(false);

  const [contmsg, SetMsg] = useState();

  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);

  const handleSetSpotify2FA = (event) => {
    SetLogin(event.target.value);
    try {
      const data = {
        name: "login-button-status",
        value: event.target.value,
      };
      ConfigService.UpdateLoginButtonStatus(data)
        .then((response) => {
          openInfoSB();
          SetMsg(response.data.message);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSetAtlassian2FA = (event) => {
    SetPaypal(event.target.value);
    try {
      const data = {
        name: "paypal-login-status",
        value: event.target.value,
      };
      ConfigService.UpdatePaypalLoginStatus(data)
        .then((response) => {
          openInfoSB();
          SetMsg(response.data.message);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSetAsana2FA = (event) => {
    SetCountdown(event.target.value);
    try {
      const data = {
        name: "countdown-status",
        value: event.target.value,
      };
      ConfigService.UpdateCountdownStatus(data)
        .then((response) => {
          openInfoSB();
          SetMsg(response.data.message);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCart = (event) => {
    SetCart(event.target.value);
    try {
      const data = {
        name: "mint-status",
        value: event.target.value,
      };
      ConfigService.UpdateAddtoCartStatus(data)
        .then((response) => {
          openInfoSB();
          SetMsg(response.data.message);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDiscord = (event) => {
    SetDiscord(event.target.value);
    try {
      const data = {
        name: "discord-status",
        value: event.target.value,
      };
      ConfigService.UpdateDiscordStatus(data)
        .then((response) => {
          openInfoSB();
          SetMsg(response.data.message);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const GetLoginBtnStatus = () => {
    ConfigService.getLoginBtnStatus()
      .then((response) => {
        SetLogin(response.data.data[0].value);
        console.log("getLoginBtnStatus ", response.data.data[0].value);
      })
      .catch((error) => {
        console.log("getPrivacyPolicy Error ", error);
      });
  };

  const GetPaypalLoginStatus = () => {
    ConfigService.getPaypalLoginStatus()
      .then((response) => {
        SetPaypal(response.data.data[0].value);
        console.log("getPaypalLoginStatus ", response.data.data[0].value);
      })
      .catch((error) => {
        console.log("getTerms Error ", error);
      });
  };

  const GetCountdownStatus = () => {
    ConfigService.getCountdownStatus()
      .then((response) => {
        SetCountdown(response.data.data[0].value);
        console.log("getCountdownStatus ", response.data.data[0].value);
      })
      .catch((error) => {
        console.log("getCountdownStatus Error ", error);
      });
  };

  const GetAddtoCartStatus = () => {
    ConfigService.getAddtoCartStatus()
      .then((response) => {
        SetCart(response.data.data[0].value);
        console.log("getAddtoCartStatus ", response.data.data[0].value);
      })
      .catch((error) => {
        console.log("getCountdownStatus Error ", error);
      });
  };

  const GetDiscordStatus = () => {
    ConfigService.getDiscordStatus()
      .then((response) => {
        SetDiscord(response.data.data[0].value);
        console.log("getDiscordStatus ", response.data.data[0].value);
      })
      .catch((error) => {
        console.log("getDiscordStatus Error ", error);
      });
  };

  useEffect(() => {
    GetLoginBtnStatus();
    GetPaypalLoginStatus();
    GetCountdownStatus();
    GetAddtoCartStatus();
    GetDiscordStatus();
  }, []);

  const renderInfoSB = (
    <MDSnackbar
      icon="notifications"
      title="RightsMint"
      content={contmsg}
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );
  return (
    <Card id="accounts">
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">Configs</MDTypography>
        </MDBox>
        {renderInfoSB}
      </MDBox>
      <MDBox pb={3} px={3}>
        <Divider />
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDBox display="flex" alignItems="center">
            {/* <MDAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
            <Icon fontSize="large">login</Icon>
            <MDBox ml={2} lineHeight={0}>
              <MDTypography variant="h5" fontWeight="medium">
                Login & Register
              </MDTypography>
              <MDTypography variant="button" color="text">
                Both Email and PayPal
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            width={{ xs: "100%", sm: "auto" }}
            mt={{ xs: 1, sm: 0 }}
          >
            <MDBox lineHeight={0} mx={2}>
              {/* <MDTypography variant="button" color="text">
                {setLogin ? "Enable" : "Disable"}
              </MDTypography> */}
            </MDBox>
            <MDBox mr={1}>
              {/* <Switch checked={setLogin} onChange={handleSetSpotify2FA} /> */}
              <ToggleButtonGroup
                color="primary"
                exclusive
                aria-label="Platform"
                value={setLogin}
                onChange={handleSetSpotify2FA}
              >
                <ToggleButton value="enable">Enable</ToggleButton>
                <ToggleButton value="disable">Disable</ToggleButton>
              </ToggleButtonGroup>
            </MDBox>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDBox display="flex" alignItems="center">
            <MDAvatar src={paypal} alt="Slack logo" size="sm" variant="rounded" />
            <MDBox ml={2} lineHeight={0}>
              <MDTypography variant="h5" fontWeight="medium">
                Login with PayPal
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            width={{ xs: "100%", sm: "auto" }}
            mt={{ xs: 1, sm: 0 }}
          >
            <MDBox lineHeight={0} mx={2}>
              {/* <MDTypography variant="button" color="text">
                {setPaypal ? "Enabled" : "Disabled"}
              </MDTypography> */}
            </MDBox>
            <MDBox mr={1}>
              {/* <Switch checked={setPaypal} onChange={handleSetAtlassian2FA} /> */}
              <ToggleButtonGroup
                color="primary"
                exclusive
                aria-label="Platform"
                value={setPaypal}
                onChange={handleSetAtlassian2FA}
              >
                <ToggleButton value="enable">Enable</ToggleButton>
                <ToggleButton value="disable">Disable</ToggleButton>
              </ToggleButtonGroup>
            </MDBox>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDBox display="flex" alignItems="center">
            <Icon fontSize="large">schedule</Icon>
            <MDBox ml={2} lineHeight={0}>
              <MDTypography variant="h5" fontWeight="medium">
                Countdown Timer
              </MDTypography>
              <MDTypography variant="button" color="text">
                Cart page
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            width={{ xs: "100%", sm: "auto" }}
            mt={{ xs: 1, sm: 0 }}
          >
            <MDBox lineHeight={0} mx={2}>
              {/* <MDTypography variant="button" color="text">
                {setCountdown ? "Enabled" : "Disabled"}
              </MDTypography> */}
            </MDBox>
            <MDBox mr={1}>
              {/* <Switch checked={setCountdown} onChange={handleSetAsana2FA} /> */}
              <ToggleButtonGroup
                color="primary"
                exclusive
                aria-label="Platform"
                value={setCountdown}
                onChange={handleSetAsana2FA}
              >
                <ToggleButton value="enable">Enable</ToggleButton>
                <ToggleButton value="disable">Disable</ToggleButton>
              </ToggleButtonGroup>
            </MDBox>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDBox display="flex" alignItems="center">
            <Icon fontSize="large">add_shopping_cart</Icon>
            <MDBox ml={2} lineHeight={0}>
              <MDTypography variant="h5" fontWeight="medium">
                Add to cart
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            width={{ xs: "100%", sm: "auto" }}
            mt={{ xs: 1, sm: 0 }}
          >
            <MDBox lineHeight={0} mx={2}>
              {/* <MDTypography variant="button" color="text">
                {cart ? "Enabled" : "Disabled"}
              </MDTypography> */}
            </MDBox>
            <MDBox mr={1}>
              {/* <Switch checked={cart} onChange={handleCart} /> */}
              <ToggleButtonGroup
                color="primary"
                exclusive
                aria-label="Platform"
                value={cart}
                onChange={handleCart}
              >
                <ToggleButton value="enable">Enable</ToggleButton>
                <ToggleButton value="disable">Disable</ToggleButton>
              </ToggleButtonGroup>
            </MDBox>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDBox display="flex" alignItems="center">
            <MDAvatar src={discordss} alt="Slack logo" size="sm" variant="rounded" />
            <MDBox ml={2} lineHeight={0}>
              <MDTypography variant="h5" fontWeight="medium">
                Discord
              </MDTypography>
              <MDTypography variant="button" color="text">
                Both registration page and footer link
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            width={{ xs: "100%", sm: "auto" }}
            mt={{ xs: 1, sm: 0 }}
          >
            <MDBox lineHeight={0} mx={2}>
              {/* <MDTypography variant="button" color="text">
                {discord ? "Enabled" : "Disabled"}
              </MDTypography> */}
            </MDBox>
            <MDBox mr={1}>
              {/* <Switch checked={discord} onChange={handleDiscord} /> */}
              <ToggleButtonGroup
                color="primary"
                exclusive
                aria-label="Platform"
                value={discord}
                onChange={handleDiscord}
              >
                <ToggleButton value="enable">Enable</ToggleButton>
                <ToggleButton value="disable">Disable</ToggleButton>
              </ToggleButtonGroup>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Accounts;
