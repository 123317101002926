/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import PropTypes from "prop-types";

import "./formSelect.css";

function FormSelect({ label, errorData, onChange, id, data, ...inputProps }) {
  return (
    <div className="form-select-wrapper">
      <InputLabel className="form-select-label">{label}</InputLabel>
      <Select
        className={`form-select ${errorData?.isError && "input-error"}`}
        {...inputProps}
        onChange={onChange}
      >
        <MenuItem value="" disabled selected>
          Select
        </MenuItem>
        {data.map((value) => (
          <MenuItem value={value.id} key={value.id}>
            {value.name}
          </MenuItem>
        ))}
      </Select>
      <span className="form-input-error">{errorData?.message}</span>
    </div>
  );
}

FormSelect.propTypes = {
  label: PropTypes.string.isRequired,
  errorData: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
};

export default FormSelect;
