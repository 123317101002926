/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useRef, useEffect } from "react";

import axios from "axios";
import jwtDecode from "jwt-decode";

// react-router-dom components
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// @mui material components
import Card from "@mui/material/Card";
// import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";

// // @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import { loginFailure, loginStart, loginSuccess } from "redux/userRedux";

function Basic() {
  const currentUser = useSelector((state) => state?.user?.currentUser?.data);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const emailRef = useRef();
  const passwordRef = useRef();

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const login = async (e) => {
    e.preventDefault();
    dispatch(loginStart());
    const payload = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
    };

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_BACKEND_SERVER}/users/super-admin-login`,
        payload
      );
      const { token } = result.data;
      const decoded = jwtDecode(token);
      result.data.data.user_id = decoded.result.user_id;
      console.log("result.data.data ==> ", result.data.data);
      if (result.data.data.user_type === "superAdmin") {
        dispatch(loginSuccess(result.data));
        navigate("/dashboard/sales");
      } else {
        dispatch(loginFailure());
        setError(true);
        setErrorMsg("Unauthorized user");
      }
      // dispatch(loginSuccess(res.data));
      // localStorage.setItem("user", JSON.stringify(res.data));

      // return res.data;
    } catch (err) {
      dispatch(loginFailure());
      // console.log("err.response ==> ", err.response.data);
      setError(true);
      setErrorMsg(err.response.data.message);
      // return err.response.data;
    }
  };

  useEffect(() => {
    if (currentUser?.user_id) {
      navigate("/dashboards/sales");
    }
  }, []);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Super Admin
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput inputRef={emailRef} type="email" label="Email" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput inputRef={passwordRef} type="password" label="Password" fullWidth />
            </MDBox>
            {error && (
              <MDTypography color="error" variant="button">
                {errorMsg}
              </MDTypography>
            )}

            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={login}>
                Sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
