/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
// import MDBadge from "components/MDBadge";

// Images
import orderImage from "assets/images/product-12.jpg";

function OrderInfo() {
  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={6}>
          <MDBox display="flex" alignItems="center">
            <MDBox mr={2}>
              <MDAvatar size="xl" src={orderImage} alt="Gold Glasses" />
            </MDBox>
            <MDBox lineHeight={1}>
              <MDTypography variant="h6" fontWeight="medium">
                Yokohoma Short Stories
              </MDTypography>
              <MDBox mb={2}>
                <MDTypography variant="button" color="text">
                  Micheal Jackson
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
          <MDBox>
            <MDTypography variant="button" color="text">
              1 share
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
      <Grid container spacing={3} mt={1} alignItems="center">
        <Grid item xs={12} md={6}>
          <MDBox display="flex" alignItems="center">
            <MDBox mr={2}>
              <MDAvatar size="xl" src={orderImage} alt="Gold Glasses" />
            </MDBox>
            <MDBox lineHeight={1}>
              <MDTypography variant="h6" fontWeight="medium">
                Sounds Like Teen Spirit
              </MDTypography>
              <MDBox mb={2}>
                <MDTypography variant="button" color="text">
                  Nirvana
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
          <MDBox>
            <MDTypography variant="button" color="text">
              1 share{" "}
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
    </>
  );
}

export default OrderInfo;
