import AuthHeaderService from "utils/AuthHeader";
import axios from "axios";

class OrderService {
  static async getTotalOrderAmount() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/amount/nft-orders/all`,
      headers: AuthHeaderService.AuthHeader(),
    });
    return result;
  }

  static async getTotalOrderAmountByToday() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/amount/nft-orders/today`,
      headers: AuthHeaderService.AuthHeader(),
    });
    return result;
  }

  static async getTotalOrderAmountByLastWeek() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/amount/nft-orders/week`,
      headers: AuthHeaderService.AuthHeader(),
    });
    return result;
  }

  static async getTotalOrderAmountByLastMonth() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/amount/nft-orders/month`,
      headers: AuthHeaderService.AuthHeader(),
    });
    return result;
  }

  static async getTotalOrderCount() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/count/orders/all`,
      headers: AuthHeaderService.AuthHeader(),
    });
    return result;
  }

  static async getTotalOrderCountByToday() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/count/orders/today`,
      headers: AuthHeaderService.AuthHeader(),
    });
    return result;
  }

  static async getTotalOrderCountByLastWeek() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/count/orders/last-week`,
      headers: AuthHeaderService.AuthHeader(),
    });
    return result;
  }

  static async getTotalOrderCountByLastMonth() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/count/orders/last-month`,
      headers: AuthHeaderService.AuthHeader(),
    });
    return result;
  }

  static async getTopSellingAssets() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/dashboard/top-selling-assets`,
      headers: AuthHeaderService.AuthHeader(),
    });
    return result;
  }

  static async getAllNftOrders() {
    const result = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/nft-order-list`,
      headers: AuthHeaderService.AuthHeader(),
    });
    return result;
  }

  static async getAllNftOrdersByToday() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/orders/today`,
      headers: AuthHeaderService.AuthHeader(),
    });
    return result;
  }

  static async getAllNftOrdersByLastWeek() {
    const result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_SERVER}/orders/last-week`,
      headers: AuthHeaderService.AuthHeader(),
    });
    return result;
  }
}

export default OrderService;
