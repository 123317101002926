import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import Tooltip from "@mui/material/Tooltip";
// import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDBadgeDot from "components/MDBadgeDot";
// import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
// import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
// import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
// import SalesTable from "examples/Tables/SalesTable";
import DataTable from "examples/Tables/DataTable";
// Sales dashboard components
// import ChannelsChart from "layouts/dashboards/sales/components/ChannelsChart";

// // Data
// import defaultLineChartData from "layouts/dashboards/sales/data/defaultLineChartData";
// import horizontalBarChartData from "layouts/dashboards/sales/data/horizontalBarChartData";
// import salesTableData from "layouts/dashboards/sales/data/salesTableData";
// Sales dashboard components
// import RefundsCell from "layouts/dashboards/sales/components/RefundsCell";
import ProductCell from "layouts/dashboards/sales/components/ProductCell";
import DefaultCell from "layouts/dashboards/sales/components/DefaultCell";
import dataTableDataColumns from "layouts/dashboards/sales/data/dataTableData";
import OrderService from "services/OrderService";
import UserService from "services/UserService";

function Sales() {
  // DefaultStatisticsCard state for the dropdown value
  const [salesDropdownValue, setSalesDropdownValue] = useState("Total");
  const [customersDropdownValue, setCustomersDropdownValue] = useState("Total");
  const [revenueDropdownValue, setRevenueDropdownValue] = useState("Total");

  // DefaultStatisticsCard state for the dropdown action
  const [salesDropdown, setSalesDropdown] = useState(null);
  const [customersDropdown, setCustomersDropdown] = useState(null);
  const [revenueDropdown, setRevenueDropdown] = useState(null);

  const [orderAmount, setOrderAmount] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [numberOfUser, setNumberOfUser] = useState(0);
  const [dataTableData, setDataTableData] = useState({
    columns: dataTableDataColumns.columns,
    rows: [],
  });

  const getTotalNftOrderAmount = () => {
    OrderService.getTotalOrderAmount()
      .then((response) => {
        setOrderAmount(
          `$${
            response.data.data.total_amount === null
              ? 0
              : response.data.data.total_amount.toFixed(2)
          }`
        );
      })
      .catch((error) => {
        console.log("getTotalNftOrderAmount Error ", error);
      });
  };

  const getTotalNftOrderAmountByToday = () => {
    OrderService.getTotalOrderAmountByToday()
      .then((response) => {
        setOrderAmount(
          `$${
            response.data.data.today_amount === null
              ? 0
              : response.data.data.today_amount.toFixed(2)
          }`
        );
      })
      .catch((error) => {
        console.log("getTotalOrderAmountByToday Error ", error);
      });
  };

  const getTotalNftOrderAmountByLastWeek = () => {
    OrderService.getTotalOrderAmountByLastWeek()
      .then((response) => {
        setOrderAmount(
          `$${
            response.data.data.last_week_amount === null
              ? 0
              : response.data.data.last_week_amount.toFixed(2)
          }`
        );
      })
      .catch((error) => {
        console.log("getTotalNftOrderAmountByLastWeek Error ", error);
      });
  };

  const getTotalNftOrderAmountByLastMonth = () => {
    OrderService.getTotalOrderAmountByLastMonth()
      .then((response) => {
        setOrderAmount(
          `$${
            response.data.data.last_month_amount === null
              ? 0
              : response.data.data.last_month_amount.toFixed(2)
          }`
        );
      })
      .catch((error) => {
        console.log("getTotalOrderAmountByLastMonth Error ", error);
      });
  };

  const getTotalNoOfUser = () => {
    UserService.getTotalCount()
      .then((response) => {
        setNumberOfUser(response.data.data.total_users);
      })
      .catch((error) => {
        console.log("getTotalNoOfUser Error ", error);
      });
  };
  const getTotalNoOfUserByToday = () => {
    UserService.getTotalCountByToday()
      .then((response) => {
        setNumberOfUser(response.data.data.no_of_users);
      })
      .catch((error) => {
        console.log("getTotalNoOfUserByToday Error ", error);
      });
  };
  const getTotalNoOfUserByWeek = () => {
    UserService.getTotalCountByLastWeek()
      .then((response) => {
        setNumberOfUser(response.data.data.no_of_users);
      })
      .catch((error) => {
        console.log("getTotalNoOfUserByWeek Error ", error);
      });
  };
  const getTotalNoOfUserMonth = () => {
    UserService.getTotalCountByLastMonth()
      .then((response) => {
        setNumberOfUser(response.data.data.no_of_users);
      })
      .catch((error) => {
        console.log("getTotalCountByLastMonth Error ", error);
      });
  };

  const getTotalNftOrderCount = () => {
    OrderService.getTotalOrderCount()
      .then((response) => {
        setOrderCount(response.data.data);
      })
      .catch((error) => {
        console.log("getTotalOrderCount Error ", error);
      });
  };

  const getTotalNftOrderCountByToday = () => {
    OrderService.getTotalOrderCountByToday()
      .then((response) => {
        setOrderCount(response.data.data);
      })
      .catch((error) => {
        console.log("getTotalOrderCountByToday Error ", error);
      });
  };

  const getTotalNftOrderCountByLastWeek = () => {
    OrderService.getTotalOrderCountByLastWeek()
      .then((response) => {
        setOrderCount(response.data.data);
      })
      .catch((error) => {
        console.log("getTotalNftOrderCountByLastWeek Error ", error);
      });
  };

  const getTotalNftOrderCountByLastMonth = () => {
    OrderService.getTotalOrderCountByLastMonth()
      .then((response) => {
        setOrderCount(response.data.data);
      })
      .catch((error) => {
        console.log("getTotalNftOrderCountByLastMonth Error ", error);
      });
  };

  const getTopSellingAssets = () => {
    OrderService.getTopSellingAssets()
      .then((response) => {
        const modifiedData = response.data.products.map((item) => {
          const container = { ...item };

          const imageUrl = `${process.env.REACT_APP_IMG_URL}/assets/${container.image}`;
          container.name = <ProductCell image={imageUrl} name={container.name} />;
          container.author = <DefaultCell>{container.author}</DefaultCell>;
          container.total_amount = <DefaultCell>${container.total_amount}</DefaultCell>;
          container.price = <DefaultCell>${container.price}</DefaultCell>;

          return container;
        });

        console.log("modifiedData ", modifiedData);

        setDataTableData({
          columns: dataTableDataColumns.columns,
          rows: modifiedData,
        });
      })
      .catch((error) => {
        console.log("getTopSellingAssets Error ", error);
      });
  };

  // DefaultStatisticsCard handler for the dropdown action
  const openSalesDropdown = ({ currentTarget }) => setSalesDropdown(currentTarget);
  const closeSalesDropdown = ({ currentTarget }) => {
    setSalesDropdown(null);
    setSalesDropdownValue(currentTarget.innerText || salesDropdownValue);
    if (currentTarget.innerText === "Total") {
      getTotalNftOrderAmount();
    }
    if (currentTarget.innerText === "Today") {
      getTotalNftOrderAmountByToday();
    }
    if (currentTarget.innerText === "Last 7 days") {
      getTotalNftOrderAmountByLastWeek();
    }
    if (currentTarget.innerText === "Last 30 days") {
      getTotalNftOrderAmountByLastMonth();
    }
  };
  const openCustomersDropdown = ({ currentTarget }) => setCustomersDropdown(currentTarget);
  const closeCustomersDropdown = ({ currentTarget }) => {
    setCustomersDropdown(null);
    setCustomersDropdownValue(currentTarget.innerText || salesDropdownValue);
    if (currentTarget.innerText === "Total") {
      getTotalNoOfUser();
    }
    if (currentTarget.innerText === "Today") {
      getTotalNoOfUserByToday();
    }
    if (currentTarget.innerText === "Last 7 days") {
      getTotalNoOfUserByWeek();
    }
    if (currentTarget.innerText === "Last 30 days") {
      getTotalNoOfUserMonth();
    }
  };
  const openRevenueDropdown = ({ currentTarget }) => setRevenueDropdown(currentTarget);
  const closeRevenueDropdown = ({ currentTarget }) => {
    setRevenueDropdown(null);
    setRevenueDropdownValue(currentTarget.innerText || salesDropdownValue);
    if (currentTarget.innerText === "Total") {
      getTotalNftOrderCount();
    }
    if (currentTarget.innerText === "Today") {
      getTotalNftOrderCountByToday();
    }
    if (currentTarget.innerText === "Last 7 days") {
      getTotalNftOrderCountByLastWeek();
    }
    if (currentTarget.innerText === "Last 30 days") {
      getTotalNftOrderCountByLastMonth();
    }
  };

  useEffect(() => {
    getTotalNftOrderAmount();
    getTotalNoOfUser();
    getTotalNftOrderCount();
    getTopSellingAssets();
  }, []);

  // Dropdown menu template for the DefaultStatisticsCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
      disableAutoFocusItem
    >
      <MenuItem onClick={close}>Total</MenuItem>
      <MenuItem onClick={close}>Today</MenuItem>
      <MenuItem onClick={close}>Last 7 days</MenuItem>
      <MenuItem onClick={close}>Last 30 days</MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="total sales"
                count={orderAmount}
                // percentage={{
                //   color: "success",
                //   value: "+55%",
                //   label: "since last month",
                // }}
                dropdown={{
                  action: openSalesDropdown,
                  menu: renderMenu(salesDropdown, closeSalesDropdown),
                  value: salesDropdownValue,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="total Users"
                count={numberOfUser}
                // percentage={{
                //   color: "success",
                //   value: "+12%",
                //   label: "since last month",
                // }}
                dropdown={{
                  action: openCustomersDropdown,
                  menu: renderMenu(customersDropdown, closeCustomersDropdown),
                  value: customersDropdownValue,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="total orders"
                count={orderCount}
                // percentage={{
                //   color: "secondary",
                //   value: "+$213",
                //   label: "since last month",
                // }}
                dropdown={{
                  action: openRevenueDropdown,
                  menu: renderMenu(revenueDropdown, closeRevenueDropdown),
                  value: revenueDropdownValue,
                }}
              />
            </Grid>
          </Grid>
        </MDBox>

        {/* <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={4}>
              <ChannelsChart />
            </Grid>
            <Grid item xs={12} sm={6} lg={8}>
              <DefaultLineChart
                title="Revenue"
                description={
                  <MDBox display="flex" justifyContent="space-between">
                    <MDBox display="flex" ml={-1}>
                      <MDBadgeDot color="info" size="sm" badgeContent="Facebook Ads" />
                      <MDBadgeDot color="dark" size="sm" badgeContent="Google Ads" />
                    </MDBox>
                    <MDBox mt={-4} mr={-1} position="absolute" right="1.5rem">
                      <Tooltip title="See which ads perform better" placement="left" arrow>
                        <MDButton
                          variant="outlined"
                          color="secondary"
                          size="small"
                          circular
                          iconOnly
                        >
                          <Icon>priority_high</Icon>
                        </MDButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                }
                chart={defaultLineChartData}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <HorizontalBarChart title="Sales by age" chart={horizontalBarChartData} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <SalesTable title="Sales by Country" rows={salesTableData} />
            </Grid>
          </Grid>
        </MDBox> */}

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <MDTypography variant="h6" fontWeight="medium">
                  Top Selling Products
                </MDTypography>
              </MDBox>
              <MDBox py={1}>
                <DataTable
                  table={dataTableData}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  isSorted={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Sales;
