import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import dataTableColumns from "layouts/admin/admin-list/data/dataTableColumns";
import UserService from "services/UserService";

import moment from "moment";

import { ExportToCsv } from "export-to-csv";

const options = {
  filename: "user-data",
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: dataTableColumns.columns.map((c) => c.Header),
};

const csvExporter = new ExportToCsv(options);

function UserList() {
  const [dataTableData, setDataTableData] = useState({
    columns: dataTableColumns.columns,
    rows: [],
  });
  // eslint-disable-next-line no-unused-vars
  const [users, setUsers] = useState([]);

  const exportToCSV = () => {
    // eslint-disable-next-line camelcase
    const modifiedExportData = dataTableData.rows.map(
      // eslint-disable-next-line camelcase
      ({ full_user_id, first_name, email, created_at }) => {
        // eslint-disable-next-line camelcase
        const container = { full_user_id, first_name, email, created_at };
        container.created_at = moment(container.created_at).format("MMM d, YYYY");
        return container;
      }
    );

    // eslint-disable-next-line arrow-body-style
    modifiedExportData.sort((a, b) => {
      return moment(b.created_at).diff(a.created_at);
    });

    csvExporter.generateCsv(modifiedExportData);
  };

  const getAllUsers = () => {
    UserService.getAllAdmins()
      .then((response) => {
        console.log("modified response ==> ", response);
        const modifiedResult = response.data.data.map((item) => {
          const container = { ...item };
          container.full_user_id = `${container.user_id}`;
          container.user_id = `${container.user_id}`;
          container.first_name = `${container.first_name} ${container.last_name}`;
          container.created_at = moment(container.created_at).format("MMM d, YYYY");
          return container;
        });
        console.log("modifiedResult ==> ", modifiedResult);
        // eslint-disable-next-line arrow-body-style
        modifiedResult.sort((a, b) => {
          return moment(b.created_at).diff(a.created_at);
        });

        setUsers(modifiedResult);

        setDataTableData({
          columns: dataTableColumns.columns,
          rows: modifiedResult,
        });
      })
      .catch((error) => {
        console.log("getAllUsers Error ", error);
      });
  };

  useEffect(() => {
    getAllUsers();
    // getAdmins();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="end" alignItems="flex-start" mb={2}>
          <MDBox display="flex">
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark" onClick={exportToCSV}>
                <Icon>description</Icon>
                &nbsp; export csv
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <Card>
          <DataTable table={dataTableData} canSearch />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UserList;
