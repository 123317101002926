/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import dataTableColumns from "layouts/ecommerce/product/product-list/data/dataTableData";
import AssetService from "services/AssetService";
import { useNavigate } from "react-router-dom";

function ProductList() {
  const [menu, setMenu] = useState(null);
  const [dataTableData, setDataTableData] = useState({
    columns: dataTableColumns.columns,
    rows: [],
  });
  const navigate = useNavigate();
  // const [assets, setAssets] = useState([]);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const goToNew = () => {
    navigate("/imo/new");
  };

  const getAllAssets = () => {
    AssetService.getAllAssets()
      .then((response) => {
        console.log("getAllAssets ", response.data.data);
        const modifiedResult = response.data.data.map((item) => {
          const container = { ...item };
          container.name = [`${container.name.slice(0, 15)}...`, { image: container.image }];
          container.valuation =
            container.valuation === null ? "Not available" : container.valuation.toString();
          container.cNFTs = container.cNFTs === null ? "Not available" : container.cNFTs.toString();
          container.release_date =
            container.release_date === null ? "Not available" : container.release_date;
          container.asset_id = container.asset_id.toString();
          return container;
        });

        // setAssets(modifiedResult);

        setDataTableData({
          columns: dataTableColumns.columns,
          rows: modifiedResult,
        });
      })
      .catch((error) => {
        console.log("getAllAssets Error ", error);
      });
  };

  useEffect(() => {
    getAllAssets();
  }, []);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Status: Active</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Deactive</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="end" alignItems="flex-start" mb={2}>
          {/* <MDButton variant="gradient" color="info">
            new order
          </MDButton> */}
          <MDBox display="flex">
            <MDBox mr={1}>
              <MDButton variant="contained" color="primary" onClick={goToNew}>
                Add new
              </MDButton>
            </MDBox>
            <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            {/* <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox> */}
          </MDBox>
        </MDBox>
        <Card>
          <DataTable table={dataTableData} canSearch />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProductList;
