/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components
// import IdCell from "layouts/ecommerce/product/product-list/components/IdCell";
import DefaultCell from "layouts/ecommerce/product/product-list/components/DefaultCell";
import StatusCell from "layouts/ecommerce/product/product-list/components/StatusCell";
import CustomerCell from "layouts/ecommerce/product/product-list/components/CustomerCell";
import ActionCell from "layouts/ecommerce/product/product-list/components/ActionCell";

// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
// import team5 from "assets/images/team-5.jpg";
// import ivana from "assets/images/ivana-squares.jpg";

const dataTableColumns = {
  columns: [
    // { Header: "Id", accessor: "id", Cell: ({ value }) => <IdCell id={value} /> },
    {
      Header: "Product",
      accessor: "name",
      Cell: ({ value: [name, data] }) => (
        <CustomerCell image={data.image} color={data.color || "dark"} name={name} />
      ),
    },
    {
      Header: "Artist",
      accessor: "author",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => {
        let status;
        if (value === 1) {
          status = <StatusCell icon="done" color="success" status="Active" />;
        } else if (value === 0) {
          status = <StatusCell icon="close" color="error" status="Deactive" />;
        }

        return status;
      },
    },
    {
      Header: "Released Date",
      accessor: "release_date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Price",
      accessor: "valuation",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Shares",
      accessor: "cNFTs",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "asset_id",
      Cell: ({ value }) => <ActionCell value={value} />,
    },
  ],

  rows: [
    {
      release_date: "1 Nov, 10:20 AM",
      status: 1,
      name: ["Yokohoma Short Stories", { image: team2 }],
      author: "Nirvana",
      valuation: "$140,20",
      cNFTs: "100",
      sales: "EDIT",
    },
    {
      release_date: "1 Nov, 10:53 AM",
      status: 1,
      name: ["Stand Together", { image: team1 }],
      author: "Nirvana",
      valuation: "$42,00",
      cNFTs: "130",
      sales: "EDIT",
    },
    {
      release_date: "1 Nov, 11:13 AM",
      status: 0,
      name: ["The Woodstock Chronicles", { image: "M" }],
      author: "Nirvana",
      valuation: "$25,50",
      cNFTs: "200",
      sales: "EDIT",
    },
    {
      release_date: "1 Nov, 12:20 PM",
      status: 1,
      name: ["Sounds Like Teen Spirit", { image: team3 }],
      author: "Nirvana",
      valuation: "$19,40",
      cNFTs: "500",
      sales: "EDIT",
    },
    {
      release_date: "1 Nov, 1:40 PM",
      status: 0,
      name: ["Ambitious", { image: team4 }],
      author: "Nirvana",
      valuation: "$44,90",
      cNFTs: "180",
      sales: "EDIT",
    },
    {
      release_date: "1 Nov, 2:19 PM",
      status: 0,
      name: ["Retroactive", { image: "L" }],
      author: "Nirvana",
      valuation: "$112,50",
      cNFTs: "100",
      sales: "EDIT",
    },
    {
      release_date: "1 Nov, 3:42 AM",
      status: 1,
      name: ["Quick On The Draw", { image: "I" }],
      author: "Nirvana",
      valuation: "$200,00",
      cNFTs: "450",
      sales: "EDIT",
    },
  ],
};

export default dataTableColumns;
