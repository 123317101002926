/* eslint-disable react/prop-types */
// ProductsList page components
import IdCell from "layouts/ecommerce/orders/order-list/components/IdCell";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";

const dataTableColumns = {
  columns: [
    { Header: "id", accessor: "user_id", Cell: ({ value }) => <IdCell id={value} /> },
    {
      Header: "name",
      accessor: "first_name",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "wallet",
      accessor: "address",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "email",
      accessor: "email",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "date",
      accessor: "created_at",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
};

export default dataTableColumns;
