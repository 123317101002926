import { useState } from "react";
// prop-types is a library for typechecking of props
// import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React components
// import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import UserService from "services/UserService";
import MDSnackbar from "components/MDSnackbar";

function ActionCell(value) {
  const [infoSB, setInfoSB] = useState(false);

  const [contmsg, SetMsg] = useState();

  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);

  const handleDelete = (values) => {
    const data = {
      user_id: values.value.value,
    };
    console.log(data);
    UserService.deleteAdmin(data)
      .then((response) => {
        if (response.data.success === 1) {
          console.log("Successfully deleted");
          openInfoSB();
          SetMsg(response.data.message);
          window.location.reload();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const renderInfoSB = (
    <MDSnackbar
      icon="notifications"
      title="RightsMint"
      content={contmsg}
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  return (
    <MDButton variant="rounded" fontSize="medium" onClick={() => handleDelete(value)}>
      {renderInfoSB}
      <Icon color="error" variant="rounded" fontSize="medium">
        delete
      </Icon>
    </MDButton>
  );
}

// Typechecking props for the DefaultCell
// ActionCell.propTypes = {
//   value: PropTypes.string.isRequired,
// };

export default ActionCell;
