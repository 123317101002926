// @mui material components
// import {Box,Button,Typography} from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Authentication layout components
// import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import { blue } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";

const primary = blue[500];

function NotFound() {
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };
  return (
    <MDBox
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: primary,
      }}
    >
      <MDTypography variant="h1" style={{ color: "white", fontSize: "7rem" }}>
        Oops!
      </MDTypography>
      <MDTypography mt={3} variant="h2" style={{ color: "white" }}>
        404 - PAGE NOT FOUND
      </MDTypography>
      <MDTypography
        mt={1}
        mb={4}
        textAlign="center"
        variant="p"
        style={{ color: "white", width: "40%" }}
      >
        The page you are looking for might have been removed had it is name changed or is
        temporarily unavailable
      </MDTypography>
      <MDButton variant="contained" size="large" color="dark" onClick={goHome}>
        Home
      </MDButton>
    </MDBox>
  );
}

export default NotFound;
