/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from "react";
import PropTypes from "prop-types";

import "./formTextarea.css";

function FormTextArea({ label, errorMessage, onChange, id, ...inputProps }) {
  const [focused, setFocused] = useState(false);
  const handleFocus = () => {
    setFocused(true);
  };

  return (
    <div className="form-textarea-wrapper">
      <label className="form-textarea-label">{label}</label>
      <div className="form-textarea-inner">
        <textarea
          className="form-textarea"
          {...inputProps}
          onChange={onChange}
          onBlur={handleFocus}
          focused={focused.toString()}
        />
      </div>
      <span className="form-textarea-error">{errorMessage}</span>
    </div>
  );
}

FormTextArea.propTypes = {
  label: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default FormTextArea;
