/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components
import IdCell from "layouts/pages/users/active-cart/components/IdCell";
import DefaultCell from "layouts/pages/users/active-cart/components/DefaultCell";
// import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
// import CustomerCell from "layouts/ecommerce/orders/order-list/components/CustomerCell";

// Images
// import team1 from "assets/images/team-1.jpg";
// import team2 from "assets/images/team-2.jpg";
// import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";
// import team5 from "assets/images/team-5.jpg";
// import ivana from "assets/images/ivana-squares.jpg";

// const allUser = [
//   {
//     id: "#10421",
//     name: "Harsha",
//     wallet: "BPeQFGFJhRZJ6D4nc6DfKJgxXB9Uzy6emuzqzZjeR2QQ",
//     email: "harshag4146@gmail.com",
//     date: "1 Nov, 10:20 AM",
//   },
//   {
//     id: "#10478",
//     name: "Sahan",
//     wallet: "4mi7zGpiNFPZ7e8aWtR8qdhmh7jRNjKrmaKsg1d4Z1hq",
//     email: "sahanwa.tnc@gmail.com",
//     date: "1 Nov, 10:20 AM",
//   },
//   {
//     id: "#10874",
//     name: "Craig",
//     wallet: "8Fq9tsJy2YDA2e956teLctb9GnzP37WoDEEXJq8sJcpE",
//     email: "nftfullstackdev@gmail.com",
//     date: "1 Nov, 10:20 AM",
//   },
// ];

const dataTableColumns = {
  columns: [
    { Header: "id", accessor: "user_id", Cell: ({ value }) => <IdCell id={value} /> },
    {
      Header: "user name",
      accessor: "first_name",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Asset Name",
      accessor: "name",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "email",
      accessor: "email",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Quantity",
      accessor: "item_quantity",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "date",
      accessor: "cart_created_at",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
};

export default dataTableColumns;
